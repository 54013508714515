



























































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import getForesightMinuteOptions from '@/utils/bookingForesightOptions'
import { mask } from 'vue-the-mask'
import DateField from '@/components/inputs/DateField.vue'
import TimeField from '@/components/inputs/TimeField.vue'

class Rule {
  index: number
  id: number
  name: string
  isDeny: boolean
  startDate: string
  endDate: string
  startTime: string
  endTime: string
  recurType: string
  weeklyMondays: boolean
  weeklyTuesdays: boolean
  weeklyWednesdays: boolean
  weeklyThursdays: boolean
  weeklyFridays: boolean
  weeklySaturdays: boolean
  weeklySundays: boolean
  excludeEmployees: boolean

  // ignoreIfLoggedIn: boolean

  constructor(data = {}) {
    this.populate(data)
  }

  populate(data) {
    if (!data) {
      data = {}
    }
    this.id = data.id || null
    this.name = data.name || ''
    this.isDeny = data.isDeny || false
    this.startDate = data.startDate || ''
    this.endDate = data.endDate || ''
    this.startTime = data.startTime || ''
    this.endTime = data.endTime || ''
    this.recurType = data.recurType || ''
    this.weeklyMondays = data.weeklyMondays || false
    this.weeklyTuesdays = data.weeklyTuesdays || false
    this.weeklyWednesdays = data.weeklyWednesdays || false
    this.weeklyThursdays = data.weeklyThursdays || false
    this.weeklyFridays = data.weeklyFridays || false
    this.weeklySaturdays = data.weeklySaturdays || false
    this.weeklySundays = data.weeklySundays || false
    this.excludeEmployees = data.excludeEmployees || false
    this.index = data.index || null
  }

  reset() {
    this.populate({})
  }

  get days() {
    const result = []
    if (this.weeklyMondays) {
      result.push('Mon')
    }
    if (this.weeklyTuesdays) {
      result.push('Tue')
    }
    if (this.weeklyWednesdays) {
      result.push('Wed')
    }
    if (this.weeklyThursdays) {
      result.push('Thu')
    }
    if (this.weeklyFridays) {
      result.push('Fri')
    }
    if (this.weeklySaturdays) {
      result.push('Sat')
    }
    if (this.weeklySundays) {
      result.push('Sun')
    }
    return result
  }

  get timeText() {
    if (this.recurType === 'Period') {
      return this.startDate + ' ' + this.startTime + ' - ' + this.endDate + ' ' + this.endTime
    } else {
      let text = this.startTime + ' - ' + this.endTime
      if (this.startDate && this.endDate) {
        if (this.startDate === this.endDate) {
          text += ' (' + this.startDate + ')'
        } else {
          text += ' (' + this.startDate + ' - ' + this.endDate + ')'
        }
      } else if (this.startDate) {
        text += ' (' + this.startDate + ' - ...)'
      } else if (this.endDate) {
        text += ' (... - ' + this.endDate + ')'
      }
      return text
    }
  }
}

@Component({
  components: { TimeField, DateField },
  directives: {
    mask,
  },
})
export default class List extends Vue {
  $refs: Vue['$refs'] & {
    saveRuleForm: {
      validate: any
    }
  }

  // ================================================================================================
  // Variables
  // ================================================================================================

  private data = {
    foresightSnapTo: '',
    rules: [],
  }

  private editRuleItem = new Rule()
  private showEditRuleDialog = false
  private nextRuleIndex = 1

  private ruleHeaders = [
    {
      text: this.$t('Type'),
      value: 'recurType',
      width: '130px',
    },
    {
      text: this.$t('Time'),
      value: 'timeText',
      width: '320px',
    },
    {
      text: this.$t('Days'),
      value: 'days',
      width: '300px',
    },
    {
      text: this.$t('Exclude if logged in'),
      value: 'excludeEmployees',
      width: '100px',
    },
    {
      text: this.$t('Name'),
      value: 'name',
    },
    {
      text: this.$t('Actions'),
      value: 'actions',
      width: '150px',
    },
  ]

  private snapToOptions = []
  private foresightMinutesOptions = []

  // ================================================================================================
  // Schedule
  // ================================================================================================

  private setDataFromResponse(response) {
    const data = {
      foresightSnapTo: '',
      rules: [],
    }
    if (response?.data?.data) {
      for (const key in response.data.data) {
        if (key !== 'rules') {
          data[key] = response.data.data[key]
        }
      }
      if (response.data.data.rules) {
        for (let i = 0; i < response.data.data.rules.length; i++) {
          const rule = new Rule(response.data.data.rules[i])
          rule.index = this.nextRuleIndex++
          data.rules.push(rule)
        }
      }
    }
    this.data = data
  }

  private mounted() {
    if (this.isNew) {
      this.data = { foresightSnapTo: '', rules: [] }
    } else {
      this.$axios
        .get(this.url)
        .then((response) => {
          this.setDataFromResponse(response)
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }

    this.$axios
      .get('/v4/site/calendars/any/portals/admin/snap-to-options')
      .then((response) => {
        this.snapToOptions = response.data.data
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load snap-to options')
      })

    this.foresightMinutesOptions = getForesightMinuteOptions((t: string) => {
      return this.$t(t)
    })
  }

  private save() {
    if (this.isNew) {
      this.$axios
        .post(this.url, this.data)
        .then((response) => {
          this.setDataFromResponse(response)
          this.$router.back()
        })
        .catch((err) => {
          if (err.response.data?.error) {
            vxm.alert.onAxiosError(err)
          }
        })
    } else {
      this.$axios
        .put(this.url, this.data)
        .then((response) => {
          this.setDataFromResponse(response)
          this.$router.back()
        })
        .catch((err) => {
          vxm.alert.onAxiosError(err)
        })
    }
  }

  private cancel() {
    this.$router.back()
  }

  private get isNew() {
    return !(this.$route.params.id && parseInt(this.$route.params.id) !== 0)
  }

  private get url() {
    const url = '/v4/site/calendars/' + this.$route.params.calendarId + '/schedules'
    return this.isNew ? url : url + '/' + this.$route.params.id
  }

  // ================================================================================================
  // Rules
  // ================================================================================================

  private formatDays(days) {
    const result = []
    if (days && days.length > 0) {
      for (let i = 0; i < days.length; i++) {
        result.push(this.$t('c:day:' + days[i]))
      }
    }
    return result.join(', ')
  }

  private get allowRules() {
    const result = []
    if (this.data?.rules) {
      this.data.rules.forEach((rule) => {
        if (!rule.isDeny) {
          result.push(rule)
        }
      })
    }
    return result
  }

  private get denyRules() {
    const result = []
    if (this.data?.rules) {
      this.data.rules.forEach((rule) => {
        if (rule.isDeny) {
          result.push(rule)
        }
      })
    }
    return result
  }

  private openNewRule(isDeny) {
    this.editRuleItem = new Rule({
      name: '',
      isDeny: isDeny,
      recurType: 'Daily',
      weeklyMondays: true,
      weeklyTuesdays: true,
      weeklyWednesdays: true,
      weeklyThursdays: true,
      weeklyFridays: true,
      weeklySaturdays: false,
      weeklySundays: false,
      excludeEmployees: false,
    })
    this.editRuleItem.index = this.nextRuleIndex++
    this.showEditRuleDialog = true
  }

  private saveRule() {
    if (this.$refs.saveRuleForm.validate()) {
      let found = false
      for (let i = 0; i < this.data.rules.length; i++) {
        if (this.data.rules[i].index === this.editRuleItem.index) {
          this.data.rules[i].populate(this.editRuleItem)
          found = true
          break
        }
      }
      if (!found) {
        this.data.rules.push(new Rule(this.editRuleItem))
      }
      this.editRuleItem.reset()
      this.showEditRuleDialog = false
    }
  }

  private editRule(item) {
    this.editRuleItem = new Rule(item)
    this.showEditRuleDialog = true
  }

  private deleteRule(item) {
    const index = this.data.rules.indexOf(item)
    if (index === -1) {
      return
    }
    this.data.rules.splice(index, 1)
  }

  private get snapToHint() {
    const snapTo = this.data?.foresightSnapTo || ''
    if (!snapTo) {
      return ''
    }
    for (let i = 0; i < this.snapToOptions.length; i++) {
      if (this.snapToOptions[i].id === snapTo) {
        return this.snapToOptions[i].description || ''
      }
    }
    return ''
  }
}
